
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { getLastPageNew, getNextPage, goToDashBoard } from '@/utils/redirectUtils';
import { getAppStatuses } from '@/api/lendio';
import { activeFlow, exitFromFlow, startFlow } from '@/api/flowSession';

@Component({
  components: { TitleDescriptionUniversal, BaseButton },
  computed: {
    ...mapGetters('steps', ['isPreviousPageExist']),
  },
})
export default class AppStatusesPage extends Vue {
  public isPreviousPageExist!: boolean;

  isButtonPreloaderShow = false;
  statusesList: Array<Record<string, string>> = [];

  dateFormat(dateString: string): string {
    const date = new Date(dateString);
    const formatTemplate = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const formattedDate = formatTemplate.format(date);
    return formattedDate;
  }
  async goToPortal(): Promise<void> {
    const { data } = await activeFlow();

    if (data.flowVersion === '108') {
      exitFromFlow();
    }

    // this.$router.push({ name: 'PortalPage' });
    goToDashBoard('dashboard');

  }
  async addApplicationAction(): Promise<void> {
    this.isButtonPreloaderShow = true;
    try {
      const currentFlow = this.$store.getters['flowStages/getCurrentFlow'];
      await startFlow(currentFlow);
      this.$router.push({ name: getNextPage() });
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isButtonPreloaderShow = false;
    }
  }
  async defineStatusesList(): Promise<void> {
    try {
      this.statusesList = await getAppStatuses();
    } catch (error: any) {
      this.popupError(error.response.data.message);
    }
  }

  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }

  setValidFlow(): void {
    if (getLastPageNew() === 'PortalPage' || getLastPageNew() === 'ThankYouPage' || getLastPageNew() === 'LendioPage') {
      this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '108', pageName: this.$route.name });
    } else {
      this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '107', pageName: this.$route.name });
    }
  }

  mounted(): void {
    this.setValidFlow();
    this.defineStatusesList();
  }
}
