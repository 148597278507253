import { render, staticRenderFns } from "./AppStatusesPage.vue?vue&type=template&id=05483afe&scoped=true"
import script from "./AppStatusesPage.vue?vue&type=script&lang=ts"
export * from "./AppStatusesPage.vue?vue&type=script&lang=ts"
import style0 from "./AppStatusesPage.vue?vue&type=style&index=0&id=05483afe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05483afe",
  null
  
)

export default component.exports