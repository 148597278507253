import { instanceApi } from './instance';

// Send lendio data
export const sendLendioData = (formData: any): Promise<void> => instanceApi.post('api/lendio/borrower', formData);

// Get application statuses
export const getAppStatuses = async (): Promise<Array<Record<string, string>>> => {
  const { data } = await instanceApi.get('api/lendio/application-statuses');
  return data;
};
